<template>
  <div>
    <!-- <a href="https://labor-up-dev.tenserpay.xyz/app-release.apk">点击下载</a> -->
    <div v-if="!isMobileStatus">
      <div id="vchat-download-pc2" class="vchat-download-pc">
        <div class="box-download">
          <div class="icon-block">
            <img src="assets/icon.png" />
            <div>{{ $t("download.title") }}</div>
          </div>
          <div style="flex-grow: 1; min-height: 624px; position: relative">
            <div class="vchat-Phone-background">
              <div class="phone-shadow">
                <div class="qrCode">
                  <img src="assets/icon.png" />
                </div>
                <div class="content">
                  <div>
                    {{ $t("download.title1") }}
                  </div>
                  <div>{{ $t("download.title2") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobileStatus" class="vchat-download-page">
      <div class="icon-block-app">
        <img src="assets/icon.png" />
        <div class="app-title">{{ $t("download.title") }}</div>
      </div>
      <div class="download-block">
        <a
          class="download-button iosImg"
          href="https://labor-up-dev.tenserpay.xyz/app-release.apk"
        >
          <img src="assets/icon.png" />
          <span>{{ $t("download.download") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      isMobileStatus: false,
    };
  },
  mounted() {
    this.isMobile();
    if (this.isMobile() !== null) {
      this.isMobileStatus = true;
    } else {
      this.isMobileStatus = false;
    }
  },
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      return flag;
    },
  },
};
</script>
<style scoped>
#vchat-download-pc {
  width: 100vw;
  height: 100%;
  overflow: auto;
  margin: 0px;
}
.vchat-download-pc {
  background: url(/assets/background.jpg) 0 0 no-repeat;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  background-size: 100% 100%;
  position: relative;
}
.vchat-download-pc .box-download {
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.vchat-download-pc .icon-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-left: 20px;
  height: 28px;
}
.vchat-download-pc .icon-block img {
  height: 28px;
  width: 32px;
  margin-right: 16px;
}
.vchat-download-pc .icon-block div {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  line-height: 28px;
  color: #151f35;
  letter-spacing: 0;
}
.vchat-download-pc .vchat-Phone-background {
  width: 280px;
  height: 500px;
  background: #fff;
  -webkit-box-shadow: 0 10px 20px 1px rgb(0 0 0 / 4%);
  box-shadow: 0 10px 20px 1px rgb(0 0 0 / 4%);
  border-radius: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.vchat-download-pc .vchat-Phone-background .phone-shadow {
  background: #fafafa;
  border-radius: 4px;
  width: 248px;
  height: 420px;
  position: absolute;
  top: 40px;
  left: 16px;
}
.vchat-download-pc .vchat-Phone-background .qrCode {
  padding-top: 92px;
  padding-left: 44px;
}
.vchat-download-pc .vchat-Phone-background .qrCode img {
  width: 160px;
  height: 160px;
}
.vchat-download-pc .vchat-Phone-background .content {
  margin-top: 20px;
  line-height: 1.4;
  position: absolute;
  width: 153px;
  left: 48px;
}
.vchat-download-pc .vchat-Phone-background .content div {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #151f35;
  letter-spacing: 0;
  text-align: center;
}
.vchat-download-pc .vchat-Phone-background .system {
  position: absolute;
  left: 24px;
  bottom: 18px;
  width: 200px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #aaafb9;
  letter-spacing: 0;
  text-align: center;
}
.vchat-download-page {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: PingFangSC-Regular;
}
.vchat-download-page .icon-block-app {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
  background: #fbfcff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.vchat-download-page .app-title {
  line-height: 30px;
  font-size: 22px;
  font-weight: 600;
  color: #151f35;
  letter-spacing: 0;
  text-align: center;
  padding-top: 12px;
}

.vchat-download-page .download-block {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.vchat-download-page .download-button {
  height: 50px;
  width: 219px;
  background: #415fff;
  border-radius: 30px;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0;
  position: relative;
  font-family: PingFangSC-Regular;
  color: #fff;
}
.vchat-download-page .iosImg img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 76px;
}
.vchat-download-page .iosImg span {
  position: absolute;
  left: 109px;
}
</style>